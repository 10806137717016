export const toNumber = function(value, defaultValue) {
  try {
    let ret = parseInt(value);
    if (Number.isInteger(ret)) {
      return ret;
    } else {
      return defaultValue;
    }
  } catch (error) {
    return defaultValue;
  }
};
