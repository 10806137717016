import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getUserHomeWork = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/get?id=${id}`);
};

const getUserHomeWorkExtraInfo = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/extra-info/get?id=${id}`);
};

///api/itedu/v1/tiku/question/get
const addEdit = (item) => {
  return ajax.post(`${base}/api/itedu/v1/user-home-work/add-edit`, item);
};

const deleteById = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-home-work/delete?id=${id}`);
};

const getAidaUserByPhone = (phone) => {
  return ajax.get(`${base}/api/itedu/v1/aida-user/get-by-phone?phone=${phone}`);
};

const getAidaUser = (id) => {
  return ajax.get(`${base}/api/itedu/v1/aida-user/get?id=${id}`);
};

const getCourseById = (courseId) => {
  return ajax.get(`${base}/api/itedu/v1/course/${courseId}`);
};

const query = (fromTime, toTime, courseId, userId, hasReview) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-home-work/query?fromTime=${fromTime}&toTime=${toTime}&userId=${userId}&courseId=${courseId}&hasReview=${hasReview}`
  );
};

const queryCount = (fromTime, toTime, courseId, userId) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-home-work/query-count?fromTime=${fromTime}&toTime=${toTime}&userId=${userId}&courseId=${courseId}`
  );
};

///api/itedu/v1/user-learn-plan/cos/url
const getCdnUrl = (key) => {
  return ajax.get(`${base}/api/itedu/v1/user-home-work/cos/url?key=${key}`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const userHomeWorkApi = {
  getUserHomeWork: getUserHomeWork,
  getUserHomeWorkExtraInfo: getUserHomeWorkExtraInfo,
  addEdit: addEdit,
  deleteById: deleteById,
  getAidaUserByPhone: getAidaUserByPhone,
  getAidaUser: getAidaUser,
  query: query,
  getCdnUrl: getCdnUrl,
  awaitChain: awaitChain,
  getCourseById: getCourseById,
  queryCount: queryCount,
};
