import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const query = (fromTime, toTime, oldCourseId, userId, hasReview) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-course-extend-review/query?fromTime=${fromTime}&toTime=${toTime}&oldCourseId=${oldCourseId}&userId=${userId}&hasReview=${hasReview}`
  );
};

const getById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/get?id=${id}`);
};

const approve = (review, newCourseId) => {
  return ajax.post(`${base}/api/itedu/v1/user-course-extend-review/approve?newCourseId=${newCourseId}`, review);
};

const reject = (review) => {
  return ajax.post(`${base}/api/itedu/v1/user-course-extend-review/reject`, review);
};

const getCosPrefix = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-course-extend-review/cos-prefix?id=${id}`);
};

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const courseExtendApi = {
  approve: approve,
  query: query,
  getById: getById,
  reject: reject,
  getCosPrefix: getCosPrefix,
  awaitChain: awaitChain,
};
