export const epochToDateString = function(epoch) {
  if (typeof epoch == "string") {
    epoch = parseInt(epoch);
  }
  let date = new Date(epoch);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D =
    date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  return Y + M + D + h + m + s;
};

export const epochToDay = function(epoch) {
  let date = new Date(epoch);
  let Y = date.getFullYear();
  Y = Y.toString();
  let M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let D = date.getDate();
  if (D < 10) {
    D = "0" + D;
  }
  console.log("epoch to date", Y, M, D);
  return Y + M + D;
};

export const timeToSecond = function(time) {
  let s = "";

  let hour = time.split(":")[0];
  let min = time.split(":")[1];
  let sec = time.split(":")[2];

  s = parseInt(hour * 3600) + parseInt(min * 60) + parseInt(sec);
  return s;
};
export const secondToTime = function(second) {
  let time;
  let hour = Math.floor(second / 3600);
  let min = Math.floor(second / 60) % 60;
  let sec = second % 60;
  if (hour < 10) {
    time = "0" + hour + ":";
  } else {
    time = hour + ":";
  }

  if (min < 10) {
    time += "0";
  }
  time += min + ":";
  if (sec < 10) {
    time += "0";
  }
  time += sec;

  return time;
};
