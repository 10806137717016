export const timeToSecond = function(time) {
  let s = "";

  let hour = time.split(":")[0];
  let min = time.split(":")[1];
  let sec = time.split(":")[2];

  s = parseInt(hour * 3600) + parseInt(min * 60) + parseInt(sec);
  return s;
};

export const strToEpoch = function(dateTimeStr) {
  try {
    let d = Date.parse(dateTimeStr);
    if (Number.isNaN(d)) {
      return 0;
    }
    return d; // 毫秒
  } catch (error) {
    return 0;
  }
};
