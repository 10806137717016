<template>
  <div class="course-extend-main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 90%">
      <el-row>
        <el-col :span="6">
          <el-form-item label="时间(必填)">
            <el-date-picker v-model="fromDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="fromTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item>
            <el-date-picker v-model="toDay" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-time-select
              placeholder="选择时间"
              v-model="toTime"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="老的课程id">
            <el-input v-model="queryCourseId"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机号">
            <el-input v-model="queryPhone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="已审核">
            <el-switch v-model="hasReview" active-text="已审核" inactive-text="未审核"> </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="button-row">
        <el-form-item>
          <el-button type="primary" @click="onQuery">查询</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="table">
      <el-table :data="tableData" highlight-current-row>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column prop="courseId" label="courseId" width="100"></el-table-column>
        <el-table-column prop="courseName" label="courseName" width="100"></el-table-column>
        <el-table-column label="userPhone" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.userPhone"></div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="title" width="200"></el-table-column>
        <el-table-column prop="workText" label="workText" width="100"></el-table-column>
        <el-table-column prop="teacherText" label="teacherText" width="100"></el-table-column>
        <el-table-column label="hasReview" width="100">
          <template slot-scope="scope">
            <div v-html="scope.row.hasReview"></div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :formatter="formatTime" label="创建时间" width="100"></el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onView(scope.$index, scope.row)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { courseExtendApi } from "@/api/courseExtendApi";
import { userHomeWorkApi } from "@/api/userHomeWorkApi";
import { strToEpoch } from "@/util/timeTool.js";
import { toNumber } from "@/util/numberUtil.js";
import { epochToDateString } from "@/util/TimeUtil";

export default {
  components: {},
  data() {
    return {
      aidaUser: null,
      queryPhone: "",
      queryCourseId: "",
      hasReview: false,

      fromDay: "",
      fromTime: "",
      toDay: "",
      toTime: "",

      tableData: [],
    };
  },
  methods: {
    async onQuery() {
      this.tableData = [];
      let ret = null;
      let err = null;
      let qUserId = 0;
      let qCourseId = toNumber(this.queryCourseId, 0);

      if (this.queryPhone) {
        [ret, err] = await userHomeWorkApi.awaitChain(userHomeWorkApi.getAidaUserByPhone(this.queryPhone));
        if (ret && ret.code == 0 && ret.data) {
          qUserId = ret.data.userId;
        } else {
          msgLogger.error("系统出错" + err);
        }
      }
      let startStr = this.fromDay + " " + this.fromTime;
      let startTime = strToEpoch(startStr);
      let endStr = this.toDay + " " + this.toTime;
      let endTime = strToEpoch(endStr);
      console.log("time, userId, courseId, hasReview", startTime, endTime, qUserId, qCourseId, this.hasReview);

      if (startTime <= 0 || endTime <= 0) {
        msgLogger.warn("开始、结束时间必须有");
        return;
      }
      [ret, err] = await courseExtendApi.awaitChain(
        courseExtendApi.query(startTime, endTime, qCourseId, qUserId, this.hasReview)
      );
      console.log("query", ret);
      if (ret && ret.code == 0) {
        for (let item of ret.data) {
          this.tableData.push({
            id: item.courseExtendReview.id,
            courseId: item.courseExtendReview.oldCourseId,
            courseName: item.oldCourse.courseName,
            userPhone: item.user.userPhone,
            title: item.courseExtendReview.title,
            workText: item.courseExtendReview.workText,
            teacherText: item.courseExtendReview.teacherText,
            hasReview: item.courseExtendReview.hasReview,
            createTime: item.courseExtendReview.createTime,
          });
        }
        console.log("table data", this.tableData);
      } else {
        msgLogger.error("系统出错");
      }
    },
    onView(index, row) {
      let path = `/order/course-extend-view?id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },

    phonePrivacy(phone) {
      return phone.slice(0, 4) + "****" + phone.slice(7);
    },
    formatTime: function(row, column) {
      let num = Number(row[column.property]);
      return epochToDateString(num);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.course-extend-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
